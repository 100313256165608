<template>
  <page-main title back>
    <div class="content">
      <div class="step">
        <div class="steps step-one"></div>
        <div class="steps step-two" :class="{'this-step': step != 1}"></div>
        <div class="steps step-three" :class="{'this-step': step === 3}"></div>
      </div>
      <div class="tips">
        <div class="tip one-tip">1.验证手机号</div>
        <div class="tip" :class="{'one-tip': step != 1}">2.更换密码</div>
        <div class="tip" :class="{'one-tip': step === 3}">3.完成修改</div>
      </div>
      <div class="scan-box vertical-center" v-if="step === 1">
        <el-form label-width="100px" :model="changePassword" :rules="changePasswordRules" ref="changePassword">
          <el-form-item label="手机号码" prop="mobile" class="mobile">
            <el-input v-model="changePassword.mobile" maxlength='11' minlength='11'></el-input>
          </el-form-item>
          <div class="inline" :inline="true">
            <el-form-item label="验证码" prop="code">
              <el-input v-model="changePassword.code" class="code"></el-input>
              <el-button type="primary" v-if='countDownTime == 0' @click="sendSms" class="get-code"
                          :class="{'disable': !isPhoneNumber && isSend }">{{ isSend ? '发送中' : '获取验证码' }}
              </el-button>
              <el-button type="primary" class="get-code" v-else>{{ countDownTime }}s重新获取</el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="voice-code" v-show="showVoice"><span @click='sendVoice'>收不到验证码？试试语音验证</span></div>
            <div class="changeByPassword"><span @click="changeByPassword">密码验证</span></div>
            <el-button type="primary" @click="submitForm('changePassword')" class="bind">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="scan-box vertical-center" v-if="step === 2">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                  class="demo-ruleForm">
          <div class="inline" :inline="true">
            <div class="password-strength">
              <div class="strength danger" :class="{'modes-high': modes === 0}">高危</div>
              <div class="strength low" :class="{'modes-weak': modes === 1}">弱</div>
              <div class="strength middle" :class="{'modes-middle': modes === 2}">中</div>
              <div class="strength high" :class="{'modes-strong': modes === 3}">强</div>
            </div>
            <el-form-item label="新密码" prop="newPass">
              <el-input type="password" v-model="ruleForm.newPass" autocomplete="off" class="input-width"
                        @input="descInput"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" class="input-width"></el-input>
          </el-form-item>
          <div class="tip-message" :class="{'message-high': isShowTip}">密码不少于8位，必须包含字母</div>
          <el-form-item>
            <el-button type="primary" class="next-step" @click="bindStep('ruleForm')">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex flex-column vertical-center" v-if="step === 3">
        <img src="../../assets/user/icon_success.png" alt="" class="success-img">
        <div class="font-size-normal">恭喜您密码修改成功！</div>
        <div class="flex level-center vertical-center scan-icon-box">
          <div class="flex vertical-center">
            <el-button type="primary" class="finish" @click="finish">完成</el-button>
          </div>
        </div>
      </div>
      <el-dialog
        width="25%"
        :visible.sync="failDialogVisible"
        :center='true'
        :show-close='false'
        top='30vh'
        class='dialog-box'
      >
        <div class="flex level-center">
          <img src="@/assets/common/icon_failed.png" alt="">
        </div>
        <div class="text-ct margin-t25">{{ passwordErrorModalMsg }}
        </div>
        <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
    </span>
      </el-dialog>
      <el-dialog
        width="25%"
        :visible.sync="failTimeDialogVisible"
        :center='true'
        :show-close='false'
        top='30vh'
        class='dialog-box'
      >
        <div class="flex level-center">
          <img src="@/assets/common/icon_failed.png" alt="">
        </div>
        <div class="text-ct margin-t25">{{ timeErrorModalMsg }}
        </div>
        <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onCloseFailTimeDialogVisible">我知道了</el-button>
    </span>
      </el-dialog>
    </div>
  </page-main>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { initTCaptcha, checkPassWordStrength, cryptoEncrypt } from "@/utils/util";
import { getPersonInfo } from "@/utils/auth";

export default {
  name: "ChangePassword",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      step: 1,
      changePassword: {
        mobile: '',
        code: ''
      },
      changePasswordRules: {
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ],
      },
      failDialogVisible: false,
      failTimeDialogVisible: false,
      successDialogVisible: false,
      isSend: false,
      isVoice: false,
      countDownTime: 0,
      showVoice: false,
      smsTimer: null,
      passwordErrorModalMsg: '',
      timeErrorModalMsg: '',
      modes: -1,
      ruleForm: {
        newPass: '',
        checkPass: ''
      },
      rules: {
        newPass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      isShowTip: false
    }
  },
  computed: {
    ...mapGetters(['captchaAppId']),
    isPhoneNumber() {
      return /^\d{11}$/gi.test(this.changePassword.mobile)
    }
  },
  watch:{
    'ruleForm.newPass'(){
      this.isShowTip = false
      if (this.ruleForm.newPass.length < 8) {
        this.isShowTip = true
      }
      if (this.modes === 0) {
        this.isShowTip = true
      }
    }
  },
  components: {},
  methods: {
    ...mapActions(['getSmsCode', 'setNewPassword', 'checkCaptcha', 'paymentCheckValue']),
    descInput() {
      this.modes = checkPassWordStrength(this.ruleForm.newPass).key
    },
    changeByPassword() {
      this.$router.push({ name: 'ChangePwd' })
    },
    bindStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSmsSubmit()
        } else {
          return false
        }
      });
    },
    finish() {
      this.$router.push({ name: 'Index' })
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false
    },
    onCloseFailTimeDialogVisible() {
      this.failTimeDialogVisible = false
      this.step = 1
      this.changePassword = {
        mobile: '',
        code: ''
      }
    },
    onCloseSuccessDialogVisible() {
      this.successDialogVisible = false
      this.$router.push({ name: 'Index' })
    },
    sendSms(data) {
      if (!this.changePassword.mobile)
        return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.changePassword.mobile != getPersonInfo().mobile) {
        return this.$message.error('当前手机号和账号绑定的不一致')
      }
      this.isVoice = false
      this.getVerifyCode(data)
    },
    sendVoice(data) {
      if (!this.changePassword.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isVoice) return
      this.isVoice = true
      this.clearSmsTimer()
      this.getVerifyCode(data)
    },
    getVerifyCode(data) {
      if (this.countDownTime) return
      if (!this.changePassword.mobile) {
        return this.$message.error('请输入手机号')
      }
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isSend) {
        return
      }
      this.isSend = true
      let req = {
        mobile: this.changePassword.mobile,
        clientType: 30,//1 医生端 2 代表端 30 厂家组织核心
        smsType: 102,//101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码
        isVoice: this.isVoice,
      }
      this.getSmsCode(req)
        .then(res => {
          this.$message.success('验证码已发送')
          this.isSend = false
          this.doIntervalCount()
        })
        .catch(rea => {
          this.isSend = false
          if (rea.code === 4000) {
            if (this.isVoice) {
              this.createCaptcha(this.sendVoice)
            } else {
              this.createCaptcha(this.sendSms)
            }
          } else if (rea.code === 4206) {
            this.failDialogVisible = true
            this.passwordErrorModalMsg = '输入的手机号与绑定的手机号不匹配'
          } else if (rea.code === 4016) {
            this.failDialogVisible = true
            this.passwordErrorModalMsg = '手机号未绑定'
          } else {
            this.$message.error(rea.message)
          }
        })
    },
    doIntervalCount() {
      this.countDownTime = 60
      this.smsTimer = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 30) {
          this.showVoice = true
        }
        if (this.countDownTime < 1) {
          this.isVoice = false
          this.clearSmsTimer()
        }
      }, 1000)
    },
    clearSmsTimer() {
      this.countDownTime = 0
      this.smsTimer && clearInterval(this.smsTimer)
      this.smsTimer = null
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkMobile()
        } else {
          return false
        }
      });
    },
    checkMobile() {
      let req = {
        smsSendType: '102',
        mobile: this.changePassword.mobile,
        smsCaptcha: this.changePassword.code
      }
      this.paymentCheckValue(req).then(res => {
        this.checkValue = res
        this.step = 2
      }, rea => {
        this.failDialogVisible = true
        this.passwordErrorModalMsg = rea.message
      })
    },
    handleSmsSubmit() {
      if (this.ruleForm.newPass.length < 8) {
        return this.$message.error('密码不能低于8位')
      }
      if (this.ruleForm.newPass.length > 20) {
        return this.$message.error('密码不能高于20位')
      }
      if (this.modes === 0) {
        return this.$message.error('密码必须包含数字和字母')
      }
      let req = {
        password: cryptoEncrypt(this.ruleForm.newPass),
        checkPassword: cryptoEncrypt(this.ruleForm.checkPass),
        checkValue: this.checkValue,
        pwdSecurityDegree: this.modes
      }
      this.setNewPassword(req).then(res => {
        this.step = 3
      }, rea => {
        if (rea.code === 10018) {
          this.failTimeDialogVisible = true
          this.timeErrorModalMsg = '操作超时，请重新获取验证码'
        } else {
          this.failDialogVisible = true
          this.passwordErrorModalMsg = rea.message
        }
      })
    },
    createCaptcha(cb, faii) {
      let captcha1 = new TencentCaptcha(this.captchaAppId, (res) => {
        if (res.ret === 0) {
          // 成功 处理回调
          this.onCheckCaptcha(res).then(reason => {
            cb && cb(res)
          })
        } else if (res.ret === 2) {
          // 失败
          faii && faii()
        }
      })
      captcha1.show()
    },
    onCheckCaptcha(data) {
      let req = {
        randStr: data.randstr,
        ticket: data.ticket,
        mobile: this.changePassword.mobile
      }
      return new Promise((resolve, reject) => {
        this.checkCaptcha(req).then(res => {
          resolve()
        }, rea => {
          reject()
          this.$message.error(rea.message)
        })
      })
    }
  },
  beforeCreate() {
    // 引入腾讯云sdk
    initTCaptcha()
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
$colo-border: #ddd;
.col-border {
  border: 1px solid #ddd;
}

.card {
  margin: 20px;
}

.content {
  width: 600px;
  height: 400px;
  margin: 30px auto;
}

.step {
  width: 700px;
}

.steps {
  display: inline-block;
  width: 200px;
  height: 15px;
}

.step-one {
  margin-right: 2px;
  border-radius: 8px 0 0 8px;
  background-color: #3D61E3;
}

.this-step {
  background-color: #3D61E3 !important;
}

.step-two {
  background-color: #EFF0F2;
  margin-right: 2px;
}

.step-three {
  background-color: #EFF0F2;
  border-radius: 0 8px 8px 0;
}

.scan-box {
  padding-top: 35px;
  padding-bottom: 60px;
  padding-left: 50px;
}

.scan-image {
  border: 1px solid $colo-border;
  padding: 10px;
  margin-bottom: 20px;

  img {
    width: 200px;
    height: 200px;
  }
}

.scan-icon-box {
  margin-top: 28px;
  color: color-assist;
  margin-bottom: 38px;
}

.scan-icon {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.next-step {
  margin: 40px 0 0 50px;
  width: 180px;
}

.tip {
  width: 200px;
  padding-top: 10px;
  padding-left: 60px;
  display: inline-block;
}

.one-tip {
  color: #3D61E3;
}

.back-step {
  background-color: #ffffff;
  color: #3D61E3;
}

.remarks {
  font-size: 14px;
  color: #C8C9CE;
}

.fac-image {
  display: inline-block;
  border: 1px solid $colo-border;
  padding: 20px 60px;

  img {
    width: 70px;
    height: 70px;
  }
}

.img-bind {
  width: 30px;
  height: 18px;
  margin-top: 40px;
}

.success-img {
  width: 80px;
  height: 80px;
  margin: 40px;
}

.font-size-normal {
  text-align: center;
}

.desc-one {
  display: inline-block;
  padding: 0 0 60px 120px;
  text-align: center;

  span {
    color: #7784A1;
  }
}

.desc-two {
  display: inline-block;
  padding: 0 0 60px 90px;
  text-align: center;

  span {
    color: #7784A1;
  }
}

.mobile {
  width: 480px;
}

.input-width {
  width: 350px;
}

.code {
  width: 180px;
  margin-right: 20px;
}

.get-code {
  width: 180px;
  color: #3D61E3;
  background-color: #ffffff;
}

.voice-code {
  position: absolute;
  left: 200px;
  bottom: 50px;
  color: #3D61E3;
  cursor: pointer;
}

.bind {
  margin: 40px 0 0 70px;
  width: 180px;
}

.text-ct {
  padding-top: 20px;
}

.dialog-box >>> .el-dialog__header {
  display: none;
}

.tips {
  padding-bottom: 50px;
}

.changeByPassword {
  position: absolute;
  color: #3D61E3;
  bottom: 50px;
  cursor: pointer;
}

.finish {
  width: 180px;
  margin-top: 50px;
}

.strength {
  display: inline-block;
  background-color: #EFF0F2;
  width: 40px;
  height: 16px;
  font-size: 12px;
  color: #ffffff;
  margin: 12px 3px 0 0;
  padding-left: 14px;

  .danger {
    padding-left: 8px;
  }
}

.password-strength {
  float: right;
  height: 12px;
}

.strength-color {
  background-color: #F6625E;
}

.inline {
  position: relative;
  width: 650px;
}

.modes-high {
  background-color: #F6625E;
}

.modes-weak {
  background-color: #FF8B00;
}

.modes-middle {
  background-color: #FFD200;
}

.modes-strong {
  background-color: #09BB07;
}

.tip-message {
  margin-left: 100px;
  color: #EFF0F2;

  .message-high {
    color: #F6625E;
  }
}
</style>

