<template>
  <page-main title back>
    <div class="content">
      <div class="step">
        <div class="steps step-one"></div>
        <div class="steps step-two" :class="{ 'this-step': step != 1 }"></div>
        <div class="steps step-three" :class="{ 'this-step': step === 3 }"></div>
      </div>
      <div class="tips">
        <div class="tip one-tip">1.验证原手机号</div>
        <div class="tip" :class="{ 'one-tip': step != 1 }">2.更换手机号</div>
        <div class="tip" :class="{ 'one-tip': step === 3 }">3.完成更换</div>
      </div>
      <div class="scan-box flex flex-column vertical-center" v-if="step === 1">
        <el-form label-width="120px" :model="changePhone" ref="changePhone">
          <el-form-item label="已绑定手机号" prop="oldMobile" class="mobile">
            <el-input v-model="changePhone.oldMobile" maxlength="11" minlength="11"></el-input>
          </el-form-item>
          <div class="inline" :inline="true">
            <el-form-item label="验证码" prop="oldCode">
              <el-input v-model="changePhone.oldCode" class="code"></el-input>
              <el-button
                type="primary"
                v-if="computedTime == 0"
                @click="sendSms"
                class="get-code"
                :class="{ disable: !isPhoneNumber && isSend }"
                >{{ isSend ? "发送中" : "获取验证码" }}
              </el-button>
              <el-button type="primary" class="get-code" v-else
                >{{ computedTime }}s重新获取</el-button
              >
              <div class="voice-code" @click="sendVoice" v-show="showVoice">
                收不到验证码？试试语音验证
              </div>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" @click="nextStep" class="bind">下一步</el-button>
            <div class="change-by-pwd">若原手机号无法使用，请联系管理员处理</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="scan-box flex flex-column vertical-center" v-if="step === 2">
        <el-form label-width="80px" :model="changePhone">
          <el-form-item label="新手机号" class="new-mobile">
            <el-input v-model="changePhone.newMobile" maxlength="11" minlength="11"></el-input>
          </el-form-item>
          <div class="inline" :inline="true">
            <el-form-item label="验证码">
              <el-input v-model="changePhone.newCode" class="code"></el-input>
              <el-button
                type="primary"
                v-if="newComputedTime == 0"
                @click="sendNewSms"
                class="get-code"
                :class="{ disable: !isNewPhoneNumber && isNewSend }"
                >{{ isNewSend ? "发送中" : "获取验证码" }}
              </el-button>
              <el-button type="primary" class="get-code" v-else
                >{{ newComputedTime }}s重新获取</el-button
              >
              <div class="voice-code" @click="sendNewVoice" v-show="showNewVoice">
                收不到验证码？试试语音验证
              </div>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" class="next" @click="submit">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="scan-box flex flex-column vertical-center" v-if="step === 3">
        <img src="../../assets/user/icon_success.png" alt="" class="success-img" />
        <div class="font-size-normal">恭喜您手机号更换成功！</div>
        <div class="flex level-center vertical-center scan-icon-box">
          <div class="flex vertical-center">
            <el-button type="primary" class="next-step" @click="finish">完成</el-button>
          </div>
        </div>
      </div>
      <el-dialog
        width="25%"
        :visible.sync="failDialogVisible"
        :center="true"
        :show-close="false"
        top="30vh"
        class="dialog-box"
      >
        <div class="flex level-center">
          <img src="@/assets/common/icon_failed.png" alt="" />
        </div>
        <div class="text-ct margin-t25">
          {{ passwordErrorModalMsg }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
        </span>
      </el-dialog>
    </div>
  </page-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { initTCaptcha } from "@/utils/util";
import { getPersonInfo, setPersonInfo } from "@/utils/auth";

export default {
  name: "ChangeMobile",
  data() {
    return {
      step: 1,
      changePhone: {
        oldMobile: "",
        newMobile: "",
        oldCode: "",
        newCode: "",
      },
      failDialogVisible: false,
      successDialogVisible: false,
      isSend: false,
      isNewSend: false,
      isVoice: false,
      showVoice: false,
      showNewVoice: false,
      computedTime: 0,
      newComputedTime: 0, //倒计时
      isSendSms: false,
      isNewSendSms: false,
      passwordErrorModalMsg: "",
    };
  },
  computed: {
    ...mapGetters(["captchaAppId"]),
    isPhoneNumber() {
      return /^\d{11}$/gi.test(this.changePhone.oldMobile);
    },
    isNewPhoneNumber() {
      return /^\d{11}$/gi.test(this.changePhone.newMobile);
    },
  },
  components: {},
  methods: {
    ...mapActions(["getSmsCode", "changeMobile", "checkCaptcha"]),
    nextStep() {
      if (this.changePhone.oldMobile.length === 0) {
        return this.$message.error("请输入手机号");
      }
      if (!this.changePhone.oldCode) {
        return this.$message.error("请输入手机验证码");
      }
      if (!this.isSendSms) {
        return this.$message.error("请获取手机验证码");
      }
      if (this.changePhone.oldMobile != getPersonInfo().mobile) {
        return this.$message.error("当前手机号和账号绑定的不一致");
      }
      this.step = 2;
    },
    submit() {
      if (this.changePhone.newMobile.length === 0) {
        return this.$message.error("请输入手机号");
      }
      if (!this.isNewPhoneNumber) {
        return this.$message.error("手机号码不正确");
      }
      if (!this.changePhone.newCode) {
        return this.$message.error("请输入手机验证码");
      }
      if (!this.isNewSendSms) {
        return this.$message.error("请获取手机验证码");
      }
      this.changeMobile({
        oldMobile: this.changePhone.oldMobile,
        oldCode: this.changePhone.oldCode,
        newMobile: this.changePhone.newMobile,
        newCode: this.changePhone.newCode,
      }).then(
        (res) => {
          this.handleSuccess(this.changePhone.newMobile);
          this.step = 3;
        },
        (rea) => {
          this.failDialogVisible = true;
          if (rea.code === 4013) {
            this.passwordErrorModalMsg = "旧手机号与用户不匹配，可使用验证密码更换手机号";
          } else if (rea.code === 4014) {
            this.passwordErrorModalMsg = "新手机号已经注册过了";
          } else if (rea.code === 4004) {
            this.passwordErrorModalMsg = "短信验证码无效！";
          } else if (rea.code === 2028) {
            this.passwordErrorModalMsg = "密码错误！";
          } else {
            this.passwordErrorModalMsg = rea.message;
          }
        }
      );
    },
    handleSuccess(res) {
      let info = getPersonInfo();
      info.mobile = res;
      setPersonInfo(info);
      this.$store.dispatch("getPermission");
    },
    getVeriCode(data = {}) {
      if (this.computedTime) return;
      if (this.changePhone.oldMobile.length === 0) {
        return this.$message.error("请输入手机号");
      }
      let req = {
        mobile: this.changePhone.oldMobile,
        clientType: 30, //1 医生端 2 代表端 30 厂家组织核心
        smsType: 103, //101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码
        isVoice: this.isVoice,
      };
      this.getSmsCode(req)
        .then((res) => {
          this.$message.success("验证码已发送");
          this.isSendSms = true;
          this.isSend = false;
          this.doIntervalCount();
        })
        .catch((rea) => {
          this.isSend = false;
          if (rea.code === 4000) {
            if (this.isVoice) {
              this.createCaptcha(this.sendVoice);
            } else {
              this.createCaptcha(this.sendSms);
            }
          } else {
            this.$message.error(rea.message);
          }
        });
    },
    getNewVerifyCode(data = {}) {
      if (this.newComputedTime) return;
      if (this.changePhone.newMobile.length === 0) {
        return this.$message.error("请输入手机号");
      }
      if (!this.isNewPhoneNumber) {
        return this.$message.error("手机号码不正确");
      }
      let req = {
        mobile: this.changePhone.newMobile,
        clientType: 30, //1 医生端 2 代表端 30 厂家组织核心
        smsType: 103, //101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码
        isVoice: this.isVoice,
      };
      this.getSmsCode(req)
        .then((res) => {
          this.$message.success("验证码已发送");
          this.isNewSendSms = true;
          this.isNewSend = false;
          this.doNewIntervalCount();
        })
        .catch((rea) => {
          this.isNewSend = false;
          if (rea.code === 4000) {
            if (this.isVoice) {
              this.createCaptcha(this.sendNewVoice);
            } else {
              this.createCaptcha(this.sendNewSms);
            }
          } else {
            this.$message.error(rea.message);
          }
        });
    },
    doIntervalCount() {
      this.computedTime = 60;
      let that = this;
      this.timer = setInterval(() => {
        that.computedTime--;
        if (that.computedTime < 30) {
          that.showVoice = true;
        }
        if (that.computedTime < 1) {
          that.isVoice = false;
          that.computedTime = 0;
          that.clearTimer(that.timer);
          that.isSend = false;
        }
      }, 1000);
    },
    doNewIntervalCount() {
      this.newComputedTime = 60;
      let that = this;
      this.newTimer = setInterval(() => {
        that.newComputedTime--;
        if (that.newComputedTime < 30) {
          that.showNewVoice = true;
        }
        if (that.newComputedTime < 1) {
          that.isVoice = false;
          that.newComputedTime = 0;
          that.clearTimer(that.newTimer);
          that.isNewSend = false;
        }
      }, 1000);
    },
    clearTimer(timer) {
      timer && clearInterval(timer);
      timer = null;
    },
    sendVoice(data) {
      if (!this.changePhone.oldMobile) return this.$message.error("请输入手机号");
      if (!this.isPhoneNumber) {
        return this.$message.error("手机号码不正确");
      }
      if (this.isVoice) return;
      this.isVoice = true;
      this.computedTime = 0;
      this.clearTimer(this.timer);
      this.getVeriCode(data);
    },
    sendNewVoice(data) {
      if (!this.changePhone.newMobile) return this.$message.error("请输入手机号");
      if (!this.isNewPhoneNumber) {
        return this.$message.error("手机号码不正确");
      }
      if (this.isVoice) return;
      this.isVoice = true;
      this.newComputedTime = 0;
      this.clearTimer(this.newTimer);
      this.getNewVerifyCode(data);
    },
    sendSms(data) {
      if (!this.changePhone.oldMobile) return this.$message.error("请输入手机号");
      if (!this.isPhoneNumber) {
        return this.$message.error("手机号码不正确");
      }
      if (this.changePhone.oldMobile != getPersonInfo().mobile) {
        return this.$message.error("当前手机号和账号绑定的不一致");
      }
      this.isVoice = false;
      this.getVeriCode(data);
    },
    sendNewSms(data) {
      if (!this.changePhone.newMobile) return;
      if (!this.isNewPhoneNumber) {
        return this.$message.error("手机号码不正确");
      }
      this.isVoice = false;
      this.getNewVerifyCode(data);
    },
    finish() {
      this.$router.push({ name: "Index" });
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false;
    },
    onCloseSuccessDialogVisible() {
      this.successDialogVisible = false;
      this.$router.push({ name: "Index" });
    },
    // 创建腾讯云验证 传入回调
    createCaptcha(cb, faii) {
      let captcha1 = new TencentCaptcha(this.captchaAppId, (res) => {
        if (res.ret === 0) {
          // 成功 处理回调
          this.onCheckCaptcha(res).then((reason) => {
            cb && cb(res);
          });
        } else if (res.ret === 2) {
          // 失败
          faii && faii();
        }
      });
      captcha1.show();
    },
    onCheckCaptcha(data) {
      if (this.step == 1) {
        let req = {
          randStr: data.randstr,
          ticket: data.ticket,
          mobile: this.changePhone.oldMobile,
        };
        return new Promise((resolve, reject) => {
          this.checkCaptcha(req).then(
            (res) => {
              resolve();
            },
            (rea) => {
              this.$message.error(rea.message);
              reject();
            }
          );
        });
      }
      if (this.step == 2) {
        let req = {
          randStr: data.randstr,
          ticket: data.ticket,
          mobile: this.changePhone.newMobile,
        };
        return new Promise((resolve, reject) => {
          this.checkCaptcha(req).then(
            (res) => {
              resolve();
            },
            (rea) => {
              this.$message.error(rea.message);
              reject();
            }
          );
        });
      }
    },
  },
  beforeCreate() {
    // 引入腾讯云sdk
    initTCaptcha();
  },
  beforeDestroy() {
    this.clearTimer();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
$color-border: #ddd;

.content {
  width: 600px;
  height: 400px;
  margin: 30px auto;
}

.step {
  width: 700px;

  .steps {
    display: inline-block;
    width: 200px;
    height: 15px;
  }

  .step-one {
    margin-right: 2px;
    border-radius: 8px 0 0 8px;
    background-color: #3d61e3;
  }

  .this-step {
    background-color: #3d61e3 !important;
  }

  .step-two {
    background-color: #eff0f2;
    margin-right: 2px;
  }

  .step-three {
    background-color: #eff0f2;
    border-radius: 0 8px 8px 0;
  }
}

.scan-box {
  padding-top: 35px;
  padding-bottom: 60px;

  .scan-image {
    border: 1px solid $color-border;
    padding: 10px;
    margin-bottom: 20px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .scan-icon-box {
    margin-top: 28px;
    margin-bottom: 38px;

    .scan-icon {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
  }
}

.next-step {
  margin-top: 50px;
  width: 180px;
}

.next {
  margin-top: 50px;
  margin-left: 70px;
  width: 180px;
}

.tip {
  width: 200px;
  padding-top: 10px;
  padding-left: 60px;
  display: inline-block;

  .one-tip {
    color: #3d61e3;
  }
}

.back-step {
  background-color: #ffffff;
  color: #3d61e3;
}

.remarks {
  font-size: 14px;
  color: #c8c9ce;
}

.fac-image {
  display: inline-block;
  border: 1px solid $color-border;
  padding: 20px 60px;

  img {
    width: 70px;
    height: 70px;
  }
}

.img-bind {
  width: 30px;
  height: 18px;
  margin-top: 40px;
}

.success-img {
  width: 80px;
  height: 80px;
  margin: 40px;
}

.font-size-normal {
  text-align: center;
}

.desc-one {
  display: inline-block;
  padding: 0 0 60px 120px;
  text-align: center;

  span {
    color: #7784a1;
  }
}

.desc-two {
  display: inline-block;
  padding: 0 0 60px 90px;
  text-align: center;

  span {
    color: #7784a1;
  }
}

.mobile {
  width: 500px;
}

.input-width {
  width: 350px;
}

.code {
  width: 180px;
  margin-right: 20px;
}

.get-code {
  width: 180px;
  color: #3d61e3;
  background-color: #ffffff;
}

.voice-code {
  position: absolute;
  right: 0px;
  color: #3d61e3;
  cursor: pointer;
}

.bind {
  margin: 40px 0 0 70px;
  width: 180px;
}

.text-ct {
  padding-top: 20px;
}

.dialog-box >>> .el-dialog__header {
  display: none;
}

.tips {
  padding-bottom: 50px;
}

.change-by-pwd {
  color: #7784a1;
  padding: 10px 0 0 30px;
}
</style>
